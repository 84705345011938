export const formatCurrency = (amount: number) => {
	if (amount === null || isNaN(amount) || amount.toString() === "") {
		return ""
	}
	//Code below formats value by thousands
	const formatAmount = new Intl.NumberFormat("fr-FR", {
		style: "currency",
		currency: "ZAR",
	}).format(amount)
	//Replace is done here as when running on the chrome browser above code adds a ZAR | ZA on the after being formatted, replace on comma done explictly
	return formatAmount.replace(/ZAR|R/g, "").replace(/,/g, ".").trim()
}

export const replaceLastSegmentWithHash = (url: string): string => {
	try {
		//Replace token part of url with hashes
		if (url) {
			const lastIndex = url.lastIndexOf("/")

			if (lastIndex === -1) {
				return url
			}
			const numHashes = url.length - lastIndex - 1
			const hashes = "#".repeat(numHashes)

			return url.substring(0, lastIndex + 1) + hashes
		}
	} catch (error) {
		console.log(error)
	}
	return ""
}

